import { useEffect } from 'react';

const useBeforeUnload = (message = 'Вы уверены, что хотите закрыть вкладку?') => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = message; // Required for modern browsers
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [message]);
};

export default useBeforeUnload;
