import React, { useEffect, useMemo } from 'react';
import { useAtom } from 'jotai/index';
import moment from 'moment/moment';
import classNames from 'classnames';
import styles from '../css/index.module.scss';
import PageTitle from '../../../components/PageTitle';
import MyDatePicker from '../../../components/MyDatePicker';
import MyButton from '../../../components/MyButton';
import {
  reservesDropdownSetterAtom,
  reservesInfoAtom,
  reservesModeAtom,
  reservesTableFiltersAtom,
} from '../../../atoms/reservesAtoms';
import MyInput from '../../../components/MyInput';
import ChartsIcon from '../../../assets/icons/charts.svg';
import IconButton from '../../../components/IconButton';
import Dropdown from '../../../components/Dropdown';
import ReservesTableFilters from './ReservesTableFilters';
import { DATE_FORMAT } from '../../../constants';
import { selectedAddressAtom } from '../../../atoms/accountAtoms';
import {
  closeModalAtom, isRootAtom, openInfoModalAtom, openLoadingModalAtom,
} from '../../../atoms/globalAtoms';
import ReservesServices from '../../../services/ReservesServices';
import SmallCross from '../../../assets/icons/smallCross.svg';
import Close from '../../../assets/formIcons/close.svg';
import RefreshIcon from '../../../assets/icons/refresh.svg';

function ReservesHeader() {
  const [reservesTableFilters, setReservesTableFilters] = useAtom(reservesTableFiltersAtom);
  const [reservesInfo] = useAtom(reservesInfoAtom);
  const [, setReservesDropdownSetter] = useAtom(reservesDropdownSetterAtom);
  const [selectedAddress] = useAtom(selectedAddressAtom);
  const [isRoot] = useAtom(isRootAtom);
  const [, openLoadingModal] = useAtom(openLoadingModalAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [reservesMode, setReservesMode] = useAtom(reservesModeAtom);

  const isAnyDropdownFilter = useMemo(
    () => ReservesServices.isAnyDropdownFilter(reservesTableFilters, isRoot),
    [reservesTableFilters],
  );

  const handleDate = (key) => (val) => {
    if (val && key === 'startDate' && val >= reservesTableFilters.endDate) {
      const thisDayEnd = moment(val).add(1, 'day').subtract(1, 'second').format(DATE_FORMAT);
      setReservesTableFilters({ endDate: thisDayEnd });
    }
    setReservesTableFilters({ [key]: val ? moment(val).format(DATE_FORMAT) : '' });
  };

  const onSwitchModeClick = () => {
    setReservesMode((prev) => (prev === 'table' ? 'charts' : 'table'));
  };

  const onExportClick = async () => {
    if (!isRoot && !selectedAddress) return;
    openLoadingModal();
    await ReservesServices.generateExcel(isRoot, reservesTableFilters, selectedAddress?.name);
    closeModal();
  };

  const onDeleteFiltersClick = () => {
    const newFilters = { ...reservesTableFilters };
    if (isRoot) {
      newFilters.addresses = undefined;
      newFilters.partners = undefined;
    }
    newFilters.statuses = undefined;
    newFilters.sources = undefined;
    newFilters.countFrom = undefined;
    newFilters.countTo = undefined;
    newFilters.sumFrom = undefined;
    newFilters.sumTo = undefined;
    setReservesTableFilters(newFilters);
  };

  const onRefreshClick = () => {
    setReservesTableFilters({});
  };

  useEffect(() => {
    if (isRoot) return;
    if (!selectedAddress?.id) return;

    setReservesTableFilters({ addresses: [selectedAddress.id] });
  }, [selectedAddress]);

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <div className={styles.headerLeftTop}>
          <PageTitle text="Резервы" />
        </div>
        <div className={styles.headerLeftBottom}>
          <MyDatePicker
            startDate={reservesTableFilters.startDate}
            setStartDate={handleDate('startDate')}
            endDate={reservesTableFilters.endDate}
            setEndDate={handleDate('endDate')}
          />
          {reservesMode === 'table' && (
            <>
              <Dropdown
                text="Фильтры"
                component={ReservesTableFilters}
                setSetter={setReservesDropdownSetter}
                height={window.innerHeight - 200}
                passComponentProps
              />
              {isAnyDropdownFilter && (
              <div className={classNames(styles.iconButton, styles.deleteFilters)} onClick={onDeleteFiltersClick}>
                <img src={SmallCross} alt="x" />
                <p className={styles.tableTextGray}>Сбросить</p>
              </div>
              )}
            </>
          )}
          <IconButton
            wrapperClassName={styles.refreshButton}
            onClick={onRefreshClick}
            svgPath={RefreshIcon}
          />
        </div>
      </div>
      <div className={styles.headerRight}>
        <div className={styles.headerRightTop}>
          <div className={styles.headerCount}>
            <span className={styles.headerCountLight}>Резервов:</span>
            <span className={styles.headerCountBold}>{reservesInfo.pages?.total_records || 0}</span>
            <span className={styles.headerCountLight}>Гостей:</span>
            <span className={styles.headerCountBold}>{reservesInfo.pages?.total_guests || 0}</span>
          </div>
          <MyButton
            text="Экспорт"
            onClick={onExportClick}
          />
        </div>
        <div className={styles.headerRightBottom}>
          <IconButton
            svgPath={reservesMode === 'table' ? ChartsIcon : Close}
            onClick={onSwitchModeClick}
            wrapperClassName={styles.switchModeButton}
            loading={!reservesInfo.reservations?.length && reservesMode === 'table'}
          />
          {reservesMode === 'table' && (
            <MyInput
              value={reservesTableFilters.searchText}
              onChange={(ev) => setReservesTableFilters({ searchText: ev.target.value || undefined })}
              placeholder="Поиск"
              functionAtCleaning={() => setReservesTableFilters({
                searchText: undefined,
                newDate: reservesTableFilters.searchText?.length ? '' : reservesTableFilters.date,
              })}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ReservesHeader;
