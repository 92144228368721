import React, { useCallback, useEffect } from 'react';
import { useAtom } from 'jotai/index';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import styles from '../pages/Widget/css/index.module.scss';
import {
  widgetDataAtom,
  widgetDefaultsAtom,
  widgetGettingLoadingAtom,
  widgetInputsAtom,
  widgetParamsAtom, widgetScreenStateAtom,
  widgetSelectedLanguageAtom,
} from '../atoms/widgetAtoms';
import sendRequest from '../helpers/sendRequest';
import Api from '../api';
import { closeModalAtom, modalStateAtom, openInfoModalAtom } from '../atoms/globalAtoms';
import widgetLanguages from '../pages/Widget/constants/widgetLanguages';
import PartnersServices from '../services/PartnersServices';
import WT from '../pages/Widget/constants/widgetTranslations';
import WidgetsServices from '../services/WidgetsServices';
import widgetStates from '../pages/Widget/constants/widgetStates';
import WidgetCloseModal from '../pages/Widget/components/WidgetCloseModal';

function UseWidget() {
  const [widgetData, setWidgetData] = useAtom(widgetDataAtom);
  const [widgetParams, setWidgetParams] = useAtom(widgetParamsAtom);
  const [, setWidgetDefaults] = useAtom(widgetDefaultsAtom);
  const [, setWidgetGettingLoading] = useAtom(widgetGettingLoadingAtom);
  const [widgetInputs, setWidgetInputs] = useAtom(widgetInputsAtom);
  const [widgetSelectedLanguage, setWidgetSelectedLanguage] = useAtom(widgetSelectedLanguageAtom);
  const [, openInfoModal] = useAtom(openInfoModalAtom);
  const [, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [widgetScreenState, setWidgetScreenState] = useAtom(widgetScreenStateAtom);

  const [searchParams] = useSearchParams();
  const defaultDataQuery = Object.fromEntries([...searchParams]);

  const getSlots = async () => {
    const payload = {
      id: widgetData.id,
      date: typeof widgetParams.date === 'string' ? widgetParams.date : moment(widgetParams.date).format('YYYY-MM-DD'),
      persons: widgetParams.persons,
      locale: widgetSelectedLanguage.code,
    };

    const data = await sendRequest({
      request: Api.getWidgetSlots,
      payload,
      warnErrorText: 'while getting widget slots',
      setLoading: setWidgetGettingLoading,
      showErrorInModal: false,
    });

    setWidgetInputs({ slot: null });

    if (data?.status !== 'ok') return;

    setWidgetData({
      name: data.data.address.name,
      max_guests: data.data.address.max_guests,
      slots: data.data.time_slots,
      message: data.message,
    });
  };

  const initWidget = async (addressId) => {
    const data = await sendRequest({
      request: Api.initWidget,
      payload: { id: addressId },
      warnErrorText: 'while init widget',
      setLoading: setWidgetGettingLoading,
      showErrorInModal: false,
    });

    if (data?.status !== 'ok') return;

    setWidgetDefaults({ languagesList: data.data.locales });
    setWidgetData({
      name: data.data.address.name,
      max_guests: data.data.address.max_guests,
    });
  };

  const makeBook = async () => {
    const payload = {
      id: widgetData.id,
      client: {
        name: widgetInputs.name,
        phone: widgetInputs.phoneNumber.replaceAll(' ', '').slice(1),
      },
      comments: widgetInputs.comment,
      reservation_at: `${moment(widgetParams.date).format('YYYY-MM-DD')}T${PartnersServices.convertSecondsToFormat(widgetInputs.slot)}:00.000000Z`,
      persons: widgetParams.persons,
    };

    const data = await sendRequest({
      request: Api.makeWidgetBook,
      payload,
      warnErrorText: 'while making widget book',
      setLoading: setWidgetGettingLoading,
    });

    if (data?.status !== 'ok') return;

    setWidgetScreenState(widgetStates.success);
  };

  const closeWidget = () => {
    window.parent.postMessage({ action: 'close' }, '*');
  };

  const translate = useCallback((wtObject, ...p) => {
    if (!widgetSelectedLanguage?.code) return;
    let fn = wtObject[widgetSelectedLanguage.code];
    if (typeof fn !== 'function') fn = wtObject[widgetLanguages.en.code];

    return fn(...p);
  }, [widgetSelectedLanguage]);

  const requestToCloseWidget = useCallback(() => {
    if (widgetScreenState === widgetStates.success) {
      return closeWidget();
    }

    setModalState({
      isOpen: true,
      children: <WidgetCloseModal
        t={translate}
        onClose={closeWidget}
        onCancel={closeModal}
      />,
      closeTimeoutMS: 0,
      style: {
        top: '148px', height: '162px', width: '480px', maxWidth: '100vw', borderTopLeftRadius: 0, borderTopRightRadius: 0,
      },
      overlayStyle: { backgroundColor: 'rgba(0,0,0,0.07)' },
    });
  }, [translate, widgetScreenState]);

  const initWidgetData = () => {
    try {
      if (!defaultDataQuery) return;

      if (!defaultDataQuery?.restaurantId) {
        return openInfoModal({
          text: 'Отсутствует поле "data-restaurant-id"',
        });
      }
      handleDefaultData(defaultDataQuery);
      setWidgetData({
        id: defaultDataQuery.restaurantId,
        buttonClassName: classNames(styles.myButton),
      });

      initWidget(defaultDataQuery.restaurantId).then();
    } catch (e) {
      console.warn(e);
    }
  };

  const handleDefaultData = (defaultData) => {
    if (defaultData.reserveDate && WidgetsServices.isValidAndFutureOrToday(defaultData.reserveDate)) {
      setWidgetParams({
        date: new Date(moment(defaultData.reserveDate, 'YYYY-MM-DD')),
      });
    }
    if (isFinite(defaultData.reserveGuests)) {
      setWidgetParams({
        persons: +defaultData.reserveGuests,
      });
    }
    if (defaultData.userName) {
      setWidgetInputs({
        name: defaultData.userName,
      });
    }
    if (defaultData.userPhone) {
      setWidgetInputs({
        phoneNumber: WidgetsServices.convertPhoneNumber(defaultData.userPhone),
      });
    }
    if (defaultData.managerPhone) {
      setWidgetDefaults({
        managerPhone: WidgetsServices.convertPhoneNumber(defaultData.managerPhone),
      });
    }
    if (defaultData.additionalInfo) {
      setWidgetInputs({
        comment: defaultData.additionalInfo,
      });
    }
    if (widgetLanguages[defaultData.interfaceLanguage]) {
      setWidgetSelectedLanguage(widgetLanguages[defaultData.interfaceLanguage]);
    }
    if (WidgetsServices.checkIfColorIsValid(defaultData.colorMain)) {
      document.documentElement.style.setProperty('--widget-main-color', defaultData.colorMain);
    }
    if (WidgetsServices.checkIfColorIsValid(defaultData.colorBackground)) {
      document.documentElement.style.setProperty('--widget-background-color', defaultData.colorBackground);
    }
    if (WidgetsServices.checkIfColorIsValid(defaultData.colorSecondary)) {
      document.documentElement.style.setProperty('--widget-secondary-color', defaultData.colorSecondary);
    }
    if (WidgetsServices.checkIfColorIsValid(defaultData.colorThird)) {
      document.documentElement.style.setProperty('--widget-third-color', defaultData.colorThird);
    }
    if (WidgetsServices.checkIfColorIsValid(defaultData.colorText)) {
      document.documentElement.style.setProperty('--widget-text-color', defaultData.colorText);
    }
    if (WidgetsServices.checkIfColorIsValid(defaultData.colorButton)) {
      document.documentElement.style.setProperty('--widget-button-color', defaultData.colorButton);
    }
  };

  return {
    t: translate,
    initWidgetData,
    getSlots,
    makeBook,
    requestToCloseWidget,
    closeWidget,
  };
}

export default UseWidget;
