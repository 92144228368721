import React, { useEffect } from 'react';
import { useAtom } from 'jotai/index';
import { useNavigate } from 'react-router-dom';
import styles from './css/index.module.scss';
import PartnersHeader from './components/PartnersHeader';
import PartnersTable from './components/PartnersTable';
import usePartners from '../../hooks/UsePartners';
import { partnersFiltersAtom, partnersPageStateAtom } from '../../atoms/partnersAtoms';
import { PARTNERS_PAGE_STATES } from '../../constants/statuses';
import SinglePartnerHeader from './components/SinglePartnerHeader';
import SinglePartnerInfo from './components/SinglePartnerInfo';
import AddressesTable from './components/AddressesTable';
import SingleAddressInfo from './components/SingleAddressInfo';

function Partners() {
  const navigate = useNavigate();
  const {
    savePartner, saveAddress, deleteAddress, errors,
  } = usePartners();
  const [partnersPageState] = useAtom(partnersPageStateAtom);

  useEffect(() => {
    if (window.location.href.includes('?')) {
      // navigate('.');
    }
  }, []);

  return (
    <div className={styles.partners}>
      {partnersPageState === PARTNERS_PAGE_STATES.partnersList ? (
        <>
          <PartnersHeader />
          <PartnersTable />
        </>
      ) : (
        <>
          <SinglePartnerHeader />
          {partnersPageState === PARTNERS_PAGE_STATES.singlePartnerInfo ? (
            <SinglePartnerInfo savePartner={savePartner} errors={errors} />
          ) : partnersPageState === PARTNERS_PAGE_STATES.singlePartnerAddresses ? (
            <AddressesTable deleteAddress={deleteAddress} />
          ) : partnersPageState === PARTNERS_PAGE_STATES.singlePartnerSingleAddress ? (
            <SingleAddressInfo saveAddress={saveAddress} />
          ) : null}
        </>
      )}
    </div>
  );
}

export default Partners;
